@import "variables";
@import "mixins";

section#about {
  .content p {
    padding: 0 30px 30px;

    @include tablet {
      padding: 0 10px 15px;
    }
  }
}
