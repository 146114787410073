@import "./variables";
@import "./mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  font-size: 16px;
}
.highlight--green {
  color: $green;
  font-family: inherit;
}

/**
 * CONTAINERS 
 * ROWS
 * COLUMNS
 */
.container {
  margin: 0 auto;
  width: 1200px;

  .row,
  .wp-block-columns {
    display: flex;
    flex: 1 1 0;

    .wp-block-column {
      flex: 1 1 0px;
    }
  }
}

/**
* WRAPPERS
*/

.content-wrapper {
  display: flex;
  height: 100%;

  @include mobile {
    flex-wrap: wrap;
  }

  .title {
    background: linear-gradient($text-color, $text-color);
    background-repeat: no-repeat;
    background-size: 1px 100%;
    background-position: center;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;

    @include mobile {
      width: 100%;
      bakground: none;
    }

    h4 {
      font-family: "Teko", sans-serif;
      font-size: 1.2em;
      background-color: #fff;
      color: $text-color;
      -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      -webkit-text-orientation: upright;
      text-orientation: upright;
      padding: 20px 0;
      text-transform: uppercase;
      margin: auto;
      margin-top: 100px;
      position: -webkit-sticky;
      position: sticky;
      top: 100px;

      @include mobile {
        -webkit-writing-mode: initial;
        -ms-writing-mode: initial;
        writing-mode: initial;
        position: static;
        font-size: 2rem;
        text-decoration: underline;
        margin-top: 0;
        padding-left: 15px;
      }
    }
  }

  .content {
    flex: 1 1 95%;
    padding: 100px 50px;

    @include mobile {
      flex: 1 1 100%;
      padding: 15px;
    }

    &.content--center {
      display: flex;
      align-items: center;
    }

    p {
      line-height: 1.6em;
      padding-bottom: 30px;
    }

    .content-header {
      font-weight: 700;
      font-size: 2.5rem;
      font-family: "Open Sans Condensed", sans-serif;
      padding: 0 30px 30px;
    }
  }
}

img {
  display: block;
  max-width: 100%;
}
section.section-wrapper {
  min-height: 100vh;
  display: flex;
  background-color: #ffffff;
}

@include tablet {
  section.section-wrapper {
    min-height: auto;
    width: 100%;
  }

  .container {
    width: 768px;

    .wp-block-columns {
      flex-wrap: wrap;

      .wp-block-column {
        flex: 1 0 50%;

        &:first-of-type {
          display: flex;
          align-items: flex-end;
          padding-bottom: 20px;
        }
      }
    }
  }

  .content-wrapper {
    .content {
      .content-header {
        width: 50%;
        padding: 0 15px;

        & + p {
          width: 50%;
        }
      }
    }
  }
}

@include mobile {
  .container {
    width: 100%;

    .row {
      flex-wrap: wrap;
    }

    .wp-block-columns {
      flex-wrap: wrap;

      .wp-block-column {
        flex: 1 0 100%;

        &:first-of-type {
          display: initial;
          padding-bottom: 20px;
        }
      }
    }
  }

  .content-wrapper {
    .title {
      padding-left: 5px;
    }

    .content {
      p {
        padding: 15px;
      }

      .content-header {
        padding: 0 15px;
        width: 100%;
        font-size: 2rem;
        padding-bottom: 20px;

        & + p {
          width: 100%;
        }
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
  }
}
