@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
section#loader {
  justify-content: center;
  align-items: center;
}

section#loader img {
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  max-width: 100px;
}
