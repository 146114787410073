@import "variables";
@import "mixins";

section#about-brand {
  .brand-gallery {
    width: 40%;

    .panel-image {
      padding: 0 20px 20px;
    }
  }
  .brand-content {
    width: 60%;
    padding: 0 30px 15px;
    margin-bottom: 15px;
    align-self: flex-start;

    .content-main {
      h2 {
        margin-bottom: 20px;
      }
    }

    .logo-wrapper {
      padding: 50px 0 20px;
      max-width: 150px;
    }
  }

  // Menus
  .menus-wrapper {
    border-top: 1px solid $text-color;

    .menu {
      padding: 20px;

      & + .menu {
        border-top: 1px solid $text-color;
      }

      .menu-title {
        text-decoration: underline;
        margin-bottom: 15px;
      }
      .menu-description {
        p {
          padding-bottom: 15px;
        }
      }
      .menu-files {
        display: flex;

        .file-wrapper {
          margin-right: 10px;

          a {
            text-decoration: none;
            display: inline-flex;
            align-items: center;
            color: $text-color;
            border: 1px solid $text-color;
            padding: 6px 15px;
            border-radius: 25px;
            transition: all 200ms;

            span.filename {
              padding-right: 10px;
            }

            svg {
              width: 20px;
              fill: $green;
            }

            &:hover {
              border-color: $green;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @include mobile {
    display: flex;
    flex-wrap: wrap;

    .brand-gallery,
    .brand-content {
      width: 100%;

      .panel-image {
        padding: 15px 0;
      }
    }

    .brand-content {
      padding: 0;

      p {
        padding: 0 0 15px;
      }
    }

    .brand-gallery {
      order: 1;
    }
  }
  .menus-wrapper {
    border-top: 1px solid $text-color;

    .menu {
      padding: 20px;

      & + .menu {
        border-top: 1px solid $text-color;
      }

      .menu-title {
        text-decoration: underline;
        margin-bottom: 15px;
      }
      .menu-description {
        p {
          padding-bottom: 15px;
        }
      }
      .menu-files {
        display: flex;
        flex-direction: column;

        .file-wrapper {
          margin-right: 0;
          margin-bottom: 10px;

          a {
            width: 100%;
          }
        }
      }
    }
  }
}
