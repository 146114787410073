@import "variables";
@import "mixins";

@-webkit-keyframes downwards {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

@keyframes downwards {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

section#banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
  min-height: 80vh;

  .banner-content--wrapper {
    align-self: flex-end;
    width: 100%;
    color: #fff;
    margin-bottom: 30px;

    .banner-content {
      border-bottom: 1px solid #fff;
      text-align: right;
      font-weight: 700;
      text-shadow: 1px 1px 2px #5c5c5c;
    }

    a.down-link {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .banner-scroll-icon {
      padding-top: 20px;

      svg {
        fill: #fff;

        &#mouse {
          height: 2em;
        }

        &#down {
          height: 1.5em;
          -webkit-animation: downwards 1.1s ease-in-out infinite;
          animation: downwards 1.1s ease-in-out infinite;
        }
      }
    }
  }
}

@include tablet {
  .banner-content {
    margin: 0 20px;
  }
}

@include mobile {
  section#banner {
    background-position: center;
    background-attachment: scroll;
    min-height: 50vh;

    .banner-content--wrapper {
      padding: 0 15px;

      .banner-content {
        font-size: 0.8rem;
        border-bottom: 1px solid #fff;
        text-align: right;
        font-weight: 700;
        text-shadow: 1px 1px 2px #5c5c5c;
      }

      a.down-link {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .banner-scroll-icon {
        display: none;
      }
    }
  }
}
