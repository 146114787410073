@import "variables";
@import "mixins";

.brands--wrapper {
  margin: 30px auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 25px;
  max-width: 1000px;
}

.brand {
  width: calc(50% - 20px);
  display: flex;
  position: relative;
  justify-content: center;
  overflow: hidden;
  margin: 10px;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  .brand-content {
    align-self: flex-end;
    text-align: center;
    width: 100%;
    padding: 30% 0 20px;
    color: #fff;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));

    .header {
      font-family: "Bebas Neue", cursive;
      font-size: 1.2rem;
    }
    h4 {
      font-size: 1.5rem;
    }
  }

  .brand--overlay {
    position: absolute;
    background: linear-gradient(rgba(#ffffff, 0.7), rgba(#cee4cd, 1));
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: scale(1.3);
    transition: all 300ms;
    cursor: pointer;

    .overlay-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      max-width: 30%;

      .see-more {
        font-weight: bold;
        font-size: 1.5rem;
      }
      svg {
        max-width: 50%;
      }
    }

    &:hover {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@include tablet {
  .brands--wrapper {
    padding: 0;
  }

  .brand {
    width: calc(50% - 6px);
    margin: 3px;
    justify-content: center;

    .brand-content {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 15px 0;
      background: rgba(0, 0, 0, 0.3);
      height: 100%;
    }

    .brand--overlay {
      position: absolute;
      bottom: 0;
      background: rgba(#cee4cd, 1);
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      transform: scale(1);

      .overlay-content {
        flex-direction: row;
        padding: 10px;

        .see-more {
          font-size: 1rem;
        }
        svg {
          width: 20px;
          margin-left: 10px;
        }
      }
    }
  }
}

@include mobile {
  section#brands {
    margin-bottom: 0;
  }
  .brand {
    .brand-content {
      justify-content: flex-start;
      h4 {
        font-size: 1.2rem;
      }
    }
  }
}
