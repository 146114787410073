@import "variables";
@import "mixins";

section#navigation {
  position: fixed;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  transition: all 300ms;
  z-index: 99999;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    color: #fff;
  }

  .logo-wrapper {
    max-width: 200px;
    background-color: transparent;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    transition: all 200ms ease-in-out;
    margin: 10px 0;
  }

  ul {
    display: flex;
    list-style-type: none;

    li.menu-link {
      display: flex;
      align-items: center;

      &.social-link {
        a {
          padding: 10px;
        }
      }

      a {
        font-family: "Teko", sans-serif;
        text-transform: uppercase;
        font-size: 1.3em;
        color: #fff;
        text-decoration: none;
        display: block;
        padding: 10px 20px;
        transition: all 150ms ease-in-out;

        &:hover {
          color: $green;
        }

        svg {
          width: 1.2em;
          height: auto;
          fill: #fff;
          transition: all 150ms ease-in-out;

          &:hover {
            fill: $green;
          }
        }
      }
    }
  }

  // All style for scrolled menu
  &.scrolled {
    border-bottom: 2px solid #e1e1e1;
    background: #ffffff;

    nav {
      border-bottom: none;
    }

    .logo-wrapper {
      max-width: 120px;
      -webkit-filter: none;
      filter: none;
      margin: 0;

      &:hover {
        background-color: none;
      }
    }

    ul {
      li.menu-link {
        &.social-link {
          a {
            padding: 10px;
          }
        }

        a {
          color: $text-color;

          &:hover {
            color: $green;
          }

          svg {
            width: 1.2em;
            height: auto;
            fill: $text-color;
            transition: all 150ms ease-in-out;

            &:hover {
              fill: $green;
            }
          }
        }
      }
    }
  }
}

.mobile-menu-icon {
  display: none;
}

@include mobile {
  section#navigation {
    nav {
      border-bottom: none;
    }

    .logo-wrapper {
      margin: 10px 0;
    }

    ul {
      display: none;
    }

    // All style for scrolled menu
    &.scrolled {
      border-bottom: 1px solid #e1e1e1;
      background: #ffffff;
      padding: 10px 0;

      .logo-wrapper {
        padding-left: 15px;
      }
    }

    &.scrolled {
      .mobile-menu-icon span {
        background-color: $text-color;
      }
    }
  }
  .mobile-menu-icon {
    display: block;
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 15px;
    transition: all 300ms;

    span {
      display: block;
      background-color: #fff;
      width: 100%;
      height: 3px;
      margin: 3px 0;
      transition: all 300ms;
    }
  }

  &.open {
    justify-content: center;
    align-items: center;

    span {
      margin: 0;

      &.top {
        transform-origin: center;
        transform: rotate(45deg);
        margin-bottom: -3px;
      }

      &.middle {
        opacity: 0;
        transform: scale(1.5);
      }

      &.bottom {
        transform-origin: center;
        transform: rotate(-45deg);
        margin-top: -3px;
      }
    }
  }
}
