@import "../../css/mixins";
@import "../../css/variables";

@-webkit-keyframes pulser {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
}

@keyframes pulser {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
}

#form-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(155, 155, 155, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .message {
    color: #fff;
    height: 100%;
    width: 100%;
    font-weight: bold;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms;

    &.success {
      background-color: rgba(79, 184, 71, 0.5);
    }
    &.error {
      background-color: rgba(206, 61, 61, 0.5);
    }
  }

  .loader {
    width: 40px;
    height: 40px;
    position: relative;
  }

  .loader::before,
  .loader::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: 50%;
    border: 3px solid $green;
    height: 100%;
    width: 100%;
  }

  .loader::before {
    -webkit-animation: 1500ms pulser infinite;
    animation: 1500ms pulser infinite;
  }

  .loader::after {
    -webkit-animation: 1500ms pulser 750ms infinite;
    animation: 1500ms pulser 750ms infinite;
  }
}
