// MEDIA QUERY MIXIN
@mixin tablet {
  @media screen and (max-width: 1199px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}
