@import "variables";
@import "mixins";

ul.file-list {
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;

  li.file-list-item {
    flex-basis: 50%;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $text-color;
      text-decoration: none;
      border: 1px solid $text-color;
      padding: 8px 20px;
      margin: 5px;
      border-radius: 20px;
      transition: all 200ms ease-in-out;

      &:hover {
        color: $green;
        border: 1px solid $green;

        span.dl-icon {
          fill: $green;
        }
      }

      span.dl-icon {
        width: 15px;
        height: auto;
        transition: all 200ms ease-in-out;
      }
    }
  }
}
