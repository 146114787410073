@import "variables";
@import "mixins";

.pre-footer {
  margin-bottom: 100vh;
}
section#footer {
  margin-top: 50px;
  width: 100%;
  background-image: url("../images/bg-footer.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  flex-wrap: wrap;
  align-items: flex-end;
  min-height: auto;
  position: fixed;
  bottom: 0;
  z-index: -1;
}

.footer-content-wrapper {
  display: flex;
  width: 100%;
  padding-top: 400px;
  background: linear-gradient(rgba(#cee4cd, 0), rgba(#cee4cd, 0) 20%, rgba(#cee4cd, 1) 55%, rgba(#cee4cd, 1));

  .footer-content {
    width: 60%;

    .section-header {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .brand-content-wrapper {
      .footer-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;

        .icon-wrapper {
          min-width: 15%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 15px;

          .icon {
            border: 5px double $green;
            border-radius: 50%;
            width: 60px;
            height: 60px;

            svg {
              max-width: 50px;
              padding: 10px;
              fill: $green;
            }
          }
        }

        .social-icon {
          a {
            display: block;
            padding: 5px;
            transition: all 200ms;
          }

          svg {
            width: 25px;
            fill: $green;
            transition: all 200ms;
          }

          &:hover {
            cursor: pointer;
            a {
              background-color: $green;
            }
            svg {
              fill: #fff;
            }
          }
        }

        &:last-of-type {
          margin-top: 50px;
        }
      }
    }
    .home-content-wrapper {
      display: flex;
      flex-wrap: wrap;

      .logo-wrapper {
        width: calc(100% / 3);
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 20px;

        @include mobile {
          width: 50%;
          padding: 15px;
        }

        a {
          color: $green;
          text-decoration: none;
          padding-top: 15px;

          @include mobile {
            min-height: 60px;
          }
        }
      }
    }
  }

  .form-wrapper {
    width: 40%;
    padding-left: 20px;
    position: relative;

    .form-group {
      margin-bottom: 10px;
      padding-bottom: 10px;
      padding-top: 20px;
      border-bottom: 1px solid #000;

      &:last-of-type {
        border-bottom: none;
      }

      &.error {
        color: $error;
        border-bottom: 1px solid $error;

        input,
        textarea {
          color: $error;
        }
      }

      label {
        width: 100%;
        display: block;
        text-transform: uppercase;
        font-family: "Teko", sans-serif;
        font-size: 1.4rem;
      }
      input,
      textarea {
        width: 100%;
        margin-top: 10px;
        background-color: transparent;
        border: none;
        font-size: 1rem;

        &:focus {
          outline: none;
        }
      }

      button {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        outline: none;
        width: 100%;
        justify-content: flex-end;
        transition: all 150ms;

        svg {
          width: 50px;
          padding-left: 10px;
        }

        &:hover {
          color: $green;
          fill: $green;
          cursor: pointer;
        }
      }
    }
  }
}

footer {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  height: auto;
  text-align: center;
  color: $text-color;
  font-size: 0.8rem;
}

@include tablet {
  .footer-content-wrapper {
    padding: 400px 20px 20px;

    .footer-content {
      width: 50%;

      .section-header {
        margin-bottom: 20px;
      }

      .brand-logos-wrapper {
        .logo-wrapper {
          padding: 10px;

          a {
            font-size: 0.8rem;
          }
        }
      }
    }

    .form-wrapper {
      width: 50%;
    }
  }
}

@include mobile {
  .pre-footer {
    margin-bottom: 0;
  }
  section#footer {
    position: static;
  }
  .footer-content-wrapper {
    padding-top: 100px;
    background: linear-gradient(rgba(#cee4cd, 0), rgba(#cee4cd, 0.5) 20%, rgba(#cee4cd, 1) 35%, rgba(#cee4cd, 1));

    .footer-content {
      width: 100%;

      .brand-logos-wrapper {
        .logo-wrapper {
          width: calc(100% / 2);
          padding: 30px;
        }
      }
    }

    .form-wrapper {
      width: 100%;
      padding-left: 0;
    }
  }
}
