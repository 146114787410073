@import "variables";
@import "mixins";

#mobile-menu {
  position: fixed;
  background-color: #fff;
  width: 85%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  text-align: center;
  box-shadow: 2px 2px 20px #000;

  .logo-wrapper {
    max-width: 60%;
    margin-bottom: 40px;
  }

  ul.list-item-wrapper {
    margin-left: 15px;
    list-style-type: none;
    margin-bottom: 40px;

    li {
      a {
        display: block;
        color: #000;
        text-decoration: none;
        font-size: 1.2rem;
        margin-bottom: 20px;
      }
    }
  }

  ul.social-icon-list-wrapper {
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    border-top: 1px solid $green;
    padding-top: 20px;

    li {
      margin-right: 10px;

      svg {
        width: 30px;
        fill: $green;
      }
    }
  }
}

.nav-enter,
.nav-appear {
  transform: translate(-100%);
}
.nav-enter.nav-enter-active,
.nav-appear.nav-appear-active {
  transform: translate(0);
  transition: all 300ms;
}
.nav-exit {
  transform: translate(0);
}
.nav-exit-active {
  transform: translate(-100%);
  transition: all 300ms;
}
